'use strict';

window.site = window.site || {};

/**
 * Init scripts
 * @class Site
 * @static
 */
site.FormValidation = function FormValidation() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    var $form = document.querySelector('[data-form-validation]');

    if ($form) {
      // Validate inputs
      var $inputs = [].slice.call($form.querySelectorAll('[data-validation]'));
      if ($inputs.length) {
        $inputs.forEach(function ($input) {
          $input.addEventListener('blur', function (e) {
            var type = $input.dataset.validation;
            var $error = $input.closest('.input-wrapper').nextElementSibling;
            var validationTest = void 0;

            if (type === 'email') {
              var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              validationTest = String($input.value).toLowerCase().match(regex);
            } else if (type === 'zip') {
              var _regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
              validationTest = String($input.value).toLowerCase().match(_regex);
            } else if (type === 'phone') {
              validationTest = $input.value.length === 10;
            }

            if (validationTest) {
              $error.classList.add('is-hidden');
            } else {
              $error.classList.remove('is-hidden');
            }
          });
        });
      }

      // Check on submit
      $form.addEventListener('submit', function (e) {
        var readyToSubmit = true;

        if ($inputs.length) {
          $inputs.forEach(function ($input) {
            var $error = $input.closest('.input-wrapper').nextElementSibling;

            if (!$error.classList.contains('is-hidden')) {
              readyToSubmit = false;
            }
          });
        }

        if (!readyToSubmit) {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }

        return true;
      });
    }

    // Mask
    var $dateMaskInputs = [].slice.call(document.querySelectorAll('.birthday-mask'));
    if ($dateMaskInputs.length) {
      $dateMaskInputs.forEach(function ($dateMaskInput) {
        $dateMaskInput.addEventListener('keyup', function (e) {
          var v = $dateMaskInput.value;
          if (e.code === 'Backspace') {
            if (v.length === 2) {
              $dateMaskInput.value = v.substring(0, 1);
            } else {
              if (v.length === 5) {
                $dateMaskInput.value = v.substring(0, 4);
              }
            }
          } else {
            if (v.match(/^\d{2}$/) !== null) {
              $dateMaskInput.value = v + '/';
            } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
              $dateMaskInput.value = v + '/';
            }
          }
        });
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();