'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.MobileStepsOfCare = function MobileStepsOfCare() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  var openAccordion = function openAccordion($selectedItem, $items) {
    $items.forEach(function ($item) {
      if ($item === $selectedItem) {
        // Open item
        var content = $item.querySelector('.step-content');
        $item.classList.add('is-active');
        content.style.maxHeight = content.scrollHeight + 'px';
      } else {
        // Close item
        var _content = $item.querySelector('.step-content');
        $item.classList.remove('is-active');
        _content.style.maxHeight = null;
      }
    });
  };

  var closeAccordion = function closeAccordion($selectedItem) {
    // Close item
    var content = $selectedItem.querySelector('.step-content');
    $selectedItem.classList.remove('is-active');
    content.style.maxHeight = null;
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $faqBlock = document.querySelector('.stepsOfCare');

    if ($faqBlock) {
      // Manage Accordions
      var $items = [].slice.call($faqBlock.querySelectorAll('.stepsOfCare-step'));

      $items.forEach(function ($item) {
        var $trigger = $item.querySelector('.step-header');
        $trigger.addEventListener('click', function (e) {
          e.preventDefault();

          if (!$item.classList.contains('is-active')) {
            openAccordion($item, $items);
          } else {
            closeAccordion($item);
          }
        });
      });

      // Open first element
      openAccordion($items[0], $items);
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();