'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Hero001 = function Hero001() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $scrollTrigger = document.querySelector('.hero001 .arrow-link');

    if ($scrollTrigger) {
      $scrollTrigger.addEventListener('click', function (e) {
        e.preventDefault();

        var $nextBlock = document.querySelector('.hero001').nextElementSibling;
        var $menu = document.querySelector('.header');

        window.scrollTo({
          top: $nextBlock.offsetTop - $menu.offsetHeight - 30,
          left: 0,
          behavior: 'smooth'
        });
      });
    }

    var $slider = document.querySelector('.hero001 .swiper-container');

    if ($slider) {
      var swiper = new Swiper('.swiper-container', {
        // Optional parameters
        loop: true,
        slidesPerView: 3,
        centeredSlides: true,
        // spaceBetween: 100,
        // autoplay: {
        //   delay: 3000,
        // },
        breakpoints: {
          // when window width is >= 1101px
          1101: {
            slidesPerView: 5
          }
        }
      });

      swiper.on('click', function () {
        if (swiper.clickedSlide.classList.contains('swiper-slide-active')) {
          var $button = document.querySelector('.hero001 .hero-button-wrapper .hero-button.is-active');
          $button.click();
        } else {
          swiper.slideToClickedSlide();
        }
      });

      var $buttons = document.querySelectorAll('.hero001 .hero-button-wrapper .hero-button');
      swiper.on('slideChange', function (e) {
        var $slide = document.querySelector('.hero001 .swiper-slide[data-swiper-slide-index="' + e.realIndex + '"]');
        var id = $slide.dataset.slide;
        var $previousButton = document.querySelector('.hero001 .hero-button-wrapper .hero-button.is-active');
        $buttons.forEach(function (button) {
          if (button.dataset.slide === id) {
            button.classList.remove('is-passed');
            button.classList.add('is-active');
          } else if (button === $previousButton) {
            button.classList.remove('is-active');
            button.classList.add('is-passed');
          } else {
            button.classList.remove('is-active');
            button.classList.remove('is-passed');
          }
        });
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();