'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Faq001 = function Faq001() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  var openAccordion = function openAccordion($selectedItem, $items) {
    $items.forEach(function ($item) {
      if ($item === $selectedItem) {
        // Open item
        var content = $item.querySelector('.accordion-text');
        $item.classList.add('is-active');
        content.style.maxHeight = content.scrollHeight + 'px';

        if (window.innerWidth < 768) {
          setTimeout(function (e) {
            var $menu = document.querySelector('.header');
            var $hero = document.querySelector('.hero003');
            window.scrollTo({
              top: $item.offsetTop + $hero.offsetHeight + $menu.offsetHeight,
              left: 0,
              behavior: 'smooth'
            });
          }, 300);
        }
      } else {
        // Close item
        var _content = $item.querySelector('.accordion-text');
        $item.classList.remove('is-active');
        _content.style.maxHeight = null;
      }
    });
  };

  var closeAccordion = function closeAccordion($selectedItem) {
    // Close item
    var content = $selectedItem.querySelector('.accordion-text');
    $selectedItem.classList.remove('is-active');
    content.style.maxHeight = null;
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $faqBlock = document.querySelector('.faq001');

    if ($faqBlock) {
      // Manage Accordions
      var $items = [].slice.call($faqBlock.querySelectorAll('.accordion-item'));

      $items.forEach(function ($item) {
        var $trigger = $item.querySelector('.accordion-heading');
        $trigger.addEventListener('click', function (e) {
          e.preventDefault();

          if (!$item.classList.contains('is-active')) {
            openAccordion($item, $items);
          } else {
            closeAccordion($item);
          }
        });
      });

      // Filters
      var filterItem = function filterItem(category) {
        $items.forEach(function ($item) {
          var itemCategories = $item.dataset.categories.split('|');

          if (category === 'all' || itemCategories.includes(category)) {
            $item.classList.remove('is-hidden');
          } else {
            $item.classList.add('is-hidden');
          }
        });
      };

      // Desktop Filters
      var $desktopFilters = document.querySelectorAll('.faq001-filters-desktop .filters-item');
      if ($desktopFilters.length) {
        $desktopFilters.forEach(function ($desktopFilter) {
          $desktopFilter.addEventListener('click', function (e) {
            e.preventDefault();

            $desktopFilters.forEach(function ($item) {
              return $item.classList.remove('is-active');
            });
            $desktopFilter.classList.add('is-active');

            var categoryId = $desktopFilter.dataset.value;
            filterItem(categoryId);
          });
        });
      }

      // Mobile Filters
      var $mobileSelectFilters = document.querySelector('.faq001-filters-mobile .faq001-filter-select');
      if ($mobileSelectFilters) {
        $mobileSelectFilters.addEventListener('change', function () {
          var category = $mobileSelectFilters.value;
          filterItem(category);
        });
      }
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();