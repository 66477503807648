'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Syndroms001 = function Syndroms001() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $scrollLinks = [].slice.call(document.querySelectorAll('[data-syndrom-filter]'));
    var $menu = document.querySelector('.header');

    $scrollLinks.forEach(function (item) {
      item.addEventListener('click', function (e) {
        e.preventDefault();
        var selector = item.dataset.syndromFilter;

        var $zone = document.querySelector('#' + selector);
        var topPos = $zone.getBoundingClientRect().top + window.scrollY;
        var $filters = document.querySelector('.syndroms001-filters');

        // this changes the scrolling behavior to "smooth"
        window.scroll({
          top: topPos - $menu.offsetHeight - $filters.offsetHeight - 30,
          behavior: 'smooth'
        });
      });
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();