'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.MobileAppointmentButton = function MobileAppointmentButton() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $button = document.querySelector('.mobile-appointment-button');

    if ($button) {
      window.addEventListener('scroll', function (e) {
        if (window.scrollY >= 100 && $button.classList.contains('is-expanded')) {
          $button.classList.remove('is-expanded');
        } else if (window.scrollY < 100 && !$button.classList.contains('is-expanded')) {
          $button.classList.add('is-expanded');
        }
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();