'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.ScrollAnchor = function ScrollAnchor() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if (location.hash) {
      var hash = location.hash.substring(1);
      var $target = document.getElementById(hash);

      if ($target) {
        setTimeout(function () {
          var $menu = document.querySelector('.header');
          window.scrollTo(0, $target.offsetTop - $menu.offsetHeight - 40);
        }, 1000);
      }
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();