'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Team001 = function Team001() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $slider = document.querySelector('.team001 .slider-container');

    if ($slider) {
      var flkty = new Flickity($slider, {
        // options
        cellAlign: 'left',
        contain: true,
        prevNextButtons: true,
        pageDots: false,
        imagesLoaded: true,
        groupCells: true
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();