'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Testimonials001 = function Testimonials001() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $slider = document.querySelector('.testimonials001 .testimonials-slider');

    if ($slider) {
      var flkty = new Flickity($slider, {
        // options
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        pageDots: false,
        imagesLoaded: true,
        wrapAround: true,
        draggable: window.innerWidth < 768,
        cellSelector: '.testimonial-item'
      });

      flkty.on('select', function (index) {
        var $pageText = $slider.querySelector('.pagination-text span');
        $pageText.textContent = index + 1;
      });

      var $prevButton = $slider.querySelector('.prev-button');
      $prevButton.addEventListener('click', function () {
        flkty.previous();
      });

      var $nextButton = $slider.querySelector('.next-button');
      $nextButton.addEventListener('click', function () {
        flkty.next();
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();