'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Menu = function Menu() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  // Change Menu Image
  var menuHoverChangeImage = function menuHoverChangeImage(menuKey) {
    var $activeImage = document.querySelector('.menu .image-container .menu-image-item.is-active');
    if ($activeImage && $activeImage.dataset.menuImage === menuKey) return;

    var $images = [].slice.call(document.querySelectorAll('.menu .image-container .menu-image-item'));
    if ($images.length) {
      $images.forEach(function ($image) {
        var zIndex = parseInt($image.style.zIndex, 10) || 1;

        if ($image.classList.contains('is-active') && zIndex === 3) {
          $image.style.zIndex = 2;
          setTimeout(function () {
            $image.classList.remove('is-active');
          }, 600);
        } else if ($image.dataset.menuImage === menuKey) {
          $image.style.zIndex = 3;
          $image.classList.add('is-active');
        } else {
          $image.style.zIndex = 1;
          $image.classList.remove('is-active');
        }
      });
    }
  };

  // Open Second Menu
  var openSecondMenu = function openSecondMenu(menuKey) {
    var $secondLevelMenu = document.querySelector('.menu .menu-second-level');
    $secondLevelMenu.classList.add('is-active');
    $secondLevelMenu.classList.remove('is-hovered');
    $secondLevelMenu.classList.remove('is-selected');

    var $subMenus = [].slice.call($secondLevelMenu.querySelectorAll('.menu-sublinks'));
    if ($subMenus.length) {
      $subMenus.forEach(function ($subMenu) {
        if ($subMenu.dataset.sublinks === menuKey) {
          $subMenu.classList.add('is-active');
        } else {
          $subMenu.classList.remove('is-active');
        }
      });
    }
  };

  // Close First Menu
  var closeFirstMenu = function closeFirstMenu() {
    var $firstMenu = document.querySelector('.menu .menu-first-level');

    $firstMenu.classList.remove('is-active');
    $firstMenu.classList.remove('is-hovered');
    $firstMenu.classList.remove('is-selected');

    var $links = [].slice.call($firstMenu.querySelectorAll('.menu-link'));
    $links.forEach(function ($link) {
      $link.classList.remove('is-hovered');
      $link.classList.remove('is-selected');
    });
  };

  // Close Third Menu
  var closeSecondMenu = function closeSecondMenu() {
    var $secondMenu = document.querySelector('.menu .menu-second-level');
    var $menuLinksSections = [].slice.call($secondMenu.querySelectorAll('.menu-sublinks'));

    $secondMenu.classList.remove('is-active');
    $menuLinksSections.forEach(function ($menuLinksSection) {
      $menuLinksSection.classList.remove('is-active');
      $menuLinksSection.classList.remove('is-hovered');

      var $links = [].slice.call($menuLinksSection.querySelectorAll('.menu-sublink'));
      $links.forEach(function ($link) {
        $link.classList.remove('is-hovered');
      });
    });
  };

  // Open Third Menu
  var openThirdMenu = function openThirdMenu(menuKey) {
    var $thirdLevel = document.querySelector('.menu .menu-third-level');
    $thirdLevel.classList.add('is-active');
    $thirdLevel.classList.remove('is-hovered');
    $thirdLevel.classList.remove('is-selected');

    var $subMenus = [].slice.call($thirdLevel.querySelectorAll('.menu-sublinks'));
    if ($subMenus.length) {
      $subMenus.forEach(function ($subMenu) {
        if ($subMenu.dataset.sublinks === menuKey) {
          $subMenu.classList.add('is-active');
        } else {
          $subMenu.classList.remove('is-active');
        }
      });
    }
  };

  // Close Third Menu
  var closeThirdMenu = function closeThirdMenu() {
    var $thirdMenu = document.querySelector('.menu .menu-third-level');
    var $menuLinksSections = [].slice.call($thirdMenu.querySelectorAll('.menu-sublinks'));

    $thirdMenu.classList.remove('is-active');
    $menuLinksSections.forEach(function ($menuLinksSection) {
      $menuLinksSection.classList.remove('is-active');
      $menuLinksSection.classList.remove('is-hovered');

      var $links = [].slice.call($menuLinksSection.querySelectorAll('.menu-sublink'));
      $links.forEach(function ($link) {
        $link.classList.remove('is-hovered');
      });
    });
  };

  // Open First Menu
  var openFirstMenu = function openFirstMenu(menuKey) {
    var $firstLevelMenu = document.querySelector('.menu .menu-first-level');
    $firstLevelMenu.classList.add('is-selected');

    closeThirdMenu();
    menuHoverChangeImage(menuKey);
    openSecondMenu(menuKey);

    var $links = [].slice.call($firstLevelMenu.querySelectorAll('.menu-link'));
    if ($links.length) {
      $links.forEach(function ($link) {
        if ($link.dataset.menuKey === menuKey) {
          $link.classList.add('is-hovered');
          $link.classList.add('is-selected');
        } else {
          $link.classList.add('is-hovered');
          $link.classList.remove('is-selected');
        }
      });
    }
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $menu = document.querySelector('.menu');

    if ($menu) {
      var $triggers = [].slice.call(document.querySelectorAll('[data-open-menu]'));

      // Open Menu
      if ($triggers.length) {
        $triggers.forEach(function ($trigger) {
          $trigger.addEventListener('click', function (e) {
            e.preventDefault();

            $menu.classList.add('is-active');
            var $body = document.querySelector('body');
            $body.style.overflow = 'hidden';

            if ($trigger.dataset.openMenu.length) {
              openFirstMenu($trigger.dataset.openMenu);
            } else {
              menuHoverChangeImage('main');
            }
          });
        });
      }

      // Manage Hover on First Link
      var $firstLevelMenu = $menu.querySelector('.menu-first-level');
      var $firstMenuLinks = [].slice.call($firstLevelMenu.querySelectorAll('.menu-links .menu-link'));

      // Close Menu
      var $closeTrigger = $menu.querySelector('.close-menu');
      $closeTrigger.addEventListener('click', function (e) {
        e.preventDefault();

        $menu.classList.remove('is-active');
        closeFirstMenu();
        closeSecondMenu();
        closeThirdMenu();
        var $body = document.querySelector('body');
        $body.style.overflow = 'auto';
      });

      $firstLevelMenu.addEventListener('mouseover', function (e) {
        var $target = e.target;
        if ($target.classList.contains('menu-link')) {
          $firstLevelMenu.classList.add('is-selected');

          $firstMenuLinks.forEach(function ($firstMenuLink) {
            if ($firstMenuLink === $target) {
              if (!$firstMenuLink.classList.contains('is-selected')) {
                menuHoverChangeImage($firstMenuLink.dataset.menuKey);
              }

              $firstMenuLink.classList.add('is-hovered');

              if (window.innerWidth > 900) {
                $firstMenuLink.classList.add('is-selected');

                closeThirdMenu();

                if ($firstMenuLink.classList.contains('has-children')) {
                  openSecondMenu($firstMenuLink.dataset.menuKey);
                } else {
                  closeSecondMenu();
                }
              }
            } else {
              $firstMenuLink.classList.remove('is-hovered');
              $firstMenuLink.classList.remove('is-selected');
            }
          });
        }
      });

      $firstLevelMenu.addEventListener('mouseout', function () {
        $firstLevelMenu.classList.remove('is-hovered');
      });

      var $firstLevelLinks = [].slice.call($menu.querySelectorAll('.menu-first-level .menu-links .menu-link'));
      if ($firstLevelLinks.length) {
        $firstLevelLinks.forEach(function ($firstLevelLink) {
          // Show sub-menu
          $firstLevelLink.addEventListener('click', function (e) {
            if (window.innerWidth <= 900) {
              if ($firstLevelLink.classList.contains('has-children')) {
                e.preventDefault();
                $firstLevelMenu.classList.add('is-selected');
                var menuKey = $firstLevelLink.dataset.menuKey;
                menuHoverChangeImage(menuKey);

                closeThirdMenu();

                $firstLevelLinks.forEach(function ($testLink) {
                  if ($testLink === $firstLevelLink) {
                    $testLink.classList.add('is-selected');
                  } else {
                    $testLink.classList.remove('is-selected');
                  }
                });

                openSecondMenu(menuKey);
              }
            }
          });
        });

        // Total Mouse Out
        $menu.addEventListener('mouseover', function (e) {
          var $target = e.target;

          if (!$target.classList.contains('menu-link')) {
            var $mainImage = document.querySelector('.menu-image-item[data-menu-image="main"]');
            if (!$mainImage.classList.contains('is-active') && !$firstLevelMenu.classList.contains('is-selected')) {
              menuHoverChangeImage('main');
            }
          }
        });
      }

      // Manage Hover on Second Link
      var $secondLevelMenu = $menu.querySelector('.menu-second-level');
      var $secondLevelLinks = [].slice.call($menu.querySelectorAll('.menu-second-level .menu-sublinks .menu-sublink'));

      $secondLevelMenu.addEventListener('mouseover', function (e) {
        var $target = e.target;
        if ($target.classList.contains('menu-sublink')) {
          $secondLevelMenu.classList.add('is-hovered');
          $secondLevelLinks.forEach(function ($secondLevelLink) {
            if ($secondLevelLink === $target) {
              $secondLevelLink.classList.add('is-hovered');

              if (window.innerWidth > 900) {
                $secondLevelLink.classList.add('is-selected');

                if ($secondLevelLink.classList.contains('has-children')) {
                  openThirdMenu($secondLevelLink.dataset.menuKey);
                } else {
                  closeThirdMenu();
                }
              }
            } else {
              $secondLevelLink.classList.remove('is-hovered');
              $secondLevelLink.classList.remove('is-selected');
            }
          });
        }
      });

      $secondLevelMenu.addEventListener('mouseout', function () {
        $secondLevelMenu.classList.remove('is-hovered');
      });

      if ($secondLevelLinks.length) {
        $secondLevelLinks.forEach(function ($secondLevelLink) {
          // Show sub-menu
          $secondLevelLink.addEventListener('click', function (e) {
            if (window.innerWidth <= 900) {
              if ($secondLevelLink.classList.contains('has-children')) {
                e.preventDefault();
                $secondLevelMenu.classList.add('is-selected');
                var menuKey = $secondLevelLink.dataset.menuKey;

                $secondLevelLinks.forEach(function ($testLink) {
                  if ($testLink === $secondLevelLink) {
                    $testLink.classList.add('is-selected');
                  } else {
                    $testLink.classList.remove('is-selected');
                  }
                });

                openThirdMenu(menuKey);
              }
            }
          });
        });
      }

      // Manage Hover on Second Link
      var $thirdLevelMenu = $menu.querySelector('.menu-third-level');
      var $thirdLevelLinks = [].slice.call($menu.querySelectorAll('.menu-third-level .menu-sublinks .menu-sublink'));

      $thirdLevelMenu.addEventListener('mouseover', function (e) {
        var $target = e.target;
        if ($target.classList.contains('menu-sublink')) {
          $thirdLevelMenu.classList.add('is-hovered');
          $thirdLevelLinks.forEach(function ($thirdLevelLink) {
            if ($thirdLevelLink === $target) {
              $thirdLevelLink.classList.add('is-hovered');
            } else {
              $thirdLevelLink.classList.remove('is-hovered');
            }
          });
        }
      });

      $thirdLevelMenu.addEventListener('mouseout', function () {
        $thirdLevelMenu.classList.remove('is-hovered');
      });

      // Back to the first mobile menu
      var $backFirstButton = $menu.querySelector('.menu-second-level .back-link');
      $backFirstButton.addEventListener('click', function (e) {
        e.preventDefault();
        closeSecondMenu();
      });

      // Back to the second mobile menu
      var $backSecondButton = $menu.querySelector('.menu-third-level .back-link');
      $backSecondButton.addEventListener('click', function (e) {
        e.preventDefault();
        closeThirdMenu();
      });

      // Homepage apparition
      var $homepageHeader = document.querySelector('.site-container.homepage .header');
      if ($homepageHeader) {
        window.addEventListener('scroll', function () {
          var scrollPosition = window.scrollY;

          if (scrollPosition > 400) {
            $homepageHeader.classList.remove('is-hidden');
          } else {
            $homepageHeader.classList.add('is-hidden');
          }
        });
      }
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();