'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.CreationsNavigation = function CreationsNavigation() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $navigation = document.querySelector('.creations-navigation');

    if ($navigation) {
      var checkOnScroll = function checkOnScroll() {
        if (document.documentElement.scrollTop > 0 && !$navigation.classList.contains('is-top')) {
          $navigation.classList.add('is-top');
        } else if (document.documentElement.scrollTop === 0 && $navigation.classList.contains('is-top')) {
          $navigation.classList.remove('is-top');
        }
      };

      window.onscroll = function () {
        checkOnScroll();
      };

      checkOnScroll();
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();