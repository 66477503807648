'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Arguments002 = function Arguments002() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $slider = document.querySelector('.arguments002 .arguments002-slider');

    if ($slider) {
      var flkty = new Flickity($slider, {
        // options
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        pageDots: true,
        imagesLoaded: true,
        draggable: window.innerWidth < 1024
      });

      var $navigation = document.querySelector('.arguments002 .arguments002-navigation');
      var $navigationLinks = [].slice.call($navigation.querySelectorAll('.arguments002-navigation-link'));

      $navigationLinks.forEach(function ($navigationLink) {
        $navigationLink.addEventListener('click', function (e) {
          e.preventDefault();
          var selectedIndex = $navigationLink.dataset.index;

          // Disable all links but the one clicked
          $navigationLinks.forEach(function ($testLink) {
            if ($testLink.dataset.index === selectedIndex) {
              $testLink.classList.add('is-active');
            } else {
              $testLink.classList.remove('is-active');
            }
          });

          $navigation.setAttribute('index', selectedIndex);

          flkty.select(selectedIndex);
        });
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();