'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Content001 = function Content001() {
  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var activeSectionIndex = 0;

  var activeSection = function activeSection(activeIndex) {
    if (activeIndex !== activeSectionIndex) {
      activeSectionIndex = activeIndex;

      var $navigationLinks = [].slice.call(document.querySelectorAll('.content001 .navigation-link:not(.child-item)'));

      $navigationLinks.forEach(function ($link, index) {
        if (index === activeSectionIndex) {
          $link.classList.add('is-active');
        } else {
          $link.classList.remove('is-active');
        }
      });
    }
  };

  var openAccordion = function openAccordion($selectedItem, $items) {
    $items.forEach(function ($item) {
      if ($item === $selectedItem) {
        // Open item
        var content = $item.querySelector('.accordion-text');
        $item.classList.add('is-active');
        content.style.maxHeight = content.scrollHeight + 'px';

        setTimeout(function (e) {
          var $menu = document.querySelector('.header');
          window.scrollTo({
            top: $item.offsetTop - $menu.offsetHeight - 30,
            left: 0,
            behavior: 'smooth'
          });
        }, 300);
      } else {
        // Close item
        var _content = $item.querySelector('.accordion-text');
        $item.classList.remove('is-active');
        _content.style.maxHeight = null;
      }
    });
  };

  var closeAccordion = function closeAccordion($selectedItem) {
    // Close item
    var content = $selectedItem.querySelector('.accordion-text');
    $selectedItem.classList.remove('is-active');
    content.style.maxHeight = null;
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $contentContainer = document.querySelector('.content001 .content-side');

    if ($contentContainer) {
      var $contentSections = [].slice.call($contentContainer.querySelectorAll('.content-section:not(.banner)'));
      var $menu = document.querySelector('.header');

      // Active Menu on scroll
      window.addEventListener('scroll', function () {
        var activatedSectionCount = 0;
        $contentSections.forEach(function ($section) {
          var viewportOffset = $section.getBoundingClientRect();
          var menuHeight = $menu.offsetHeight + 40;

          if (viewportOffset.top <= menuHeight) {
            activatedSectionCount += 1;
          }
        });

        var activateIndex = activatedSectionCount - 1;
        if (activateIndex < 0) activateIndex = 0;
        activeSection(activateIndex);
      });

      // Click on navigation links
      var $navigationLinks = [].slice.call(document.querySelectorAll('.content001 .navigation-link:not(.child-item)'));

      $navigationLinks.forEach(function ($link, index) {
        $link.addEventListener('click', function (e) {
          e.preventDefault();

          var selectedSection = [].slice.call(document.querySelectorAll('.content001 .content-section:not(.banner)'))[index];

          if (typeof $link.dataset.steps !== 'undefined') {
            selectedSection = document.getElementById('steps');
          }

          window.scrollTo({
            top: selectedSection.offsetTop - $menu.offsetHeight - 30,
            left: 0,
            behavior: 'smooth'
          });
        });
      });

      // Manage Accordions
      var $accordionSections = [].slice.call(document.querySelectorAll('.content001 .content-accordion'));
      if ($accordionSections.length) {
        $accordionSections.forEach(function ($accordionSection) {
          var $items = [].slice.call($accordionSection.querySelectorAll('.accordion-item'));

          $items.forEach(function ($item) {
            var $trigger = $item.querySelector('.accordion-heading');
            $trigger.addEventListener('click', function (e) {
              e.preventDefault();

              if (!$item.classList.contains('is-active')) {
                openAccordion($item, $items);
              } else {
                closeAccordion($item);
              }
            });
          });
        });
      }

      // Show more clinics
      var $trigger = $contentContainer.querySelector('.clinic-list-more-link');

      if ($trigger) {
        $trigger.addEventListener('click', function (e) {
          e.preventDefault();

          $trigger.classList.add('is-hidden');

          var $hiddenClinics = [].slice.call($contentContainer.querySelectorAll('.clinic-item.is-hidden'));
          if ($hiddenClinics.length) {
            $hiddenClinics.forEach(function ($clinic) {
              $clinic.classList.remove('is-hidden');
            });
          }
        });
      }
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();