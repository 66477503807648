'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Newsletter = function Newsletter() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $message = document.querySelector('[data-mailchimp]');

    if ($message) {
      var title = $message.dataset.title;
      var message = $message.dataset.message;
      var status = $message.dataset.mailchimp;
      Swal.fire(title, message, status);
    }

    var $forms = [].slice.call(document.querySelectorAll('[data-newsletter-form]'));

    console.log($forms);

    if ($forms.length) {
      $forms.forEach(function ($form) {
        $form.addEventListener('submit', function (e) {
          e.preventDefault();

          var url = $form.querySelector('[name="action"]').value;

          var data = new FormData($form);

          fetch(url, {
            method: 'post',
            body: data
          }).then(function (response) {
            Swal.fire($form.dataset.title, $form.dataset.message, 'success');

            $form.reset();
          });
        });
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();