'use strict';

window.site = window.site || {};

/**
 * Init scripts
 * @class Site
 * @static
 */
site.CustomPopup = function CustomPopup() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    var $banner = document.querySelector('.header .custom-popup-banner');
    if ($banner) {
      var syncMargin = function syncMargin() {
        var $siteContainer = document.getElementById('site-container');
        if (!$siteContainer.classList.contains('homepage')) {
          var $header = document.querySelector('.header');
          var height = $header.offsetHeight;
          var $main = document.querySelector('.site-container .main');

          $main.style.marginTop = height + 'px';
        }
      };

      window.addEventListener('resize', syncMargin);
      syncMargin();

      // Display banners
      var _$banners = [].slice.call(document.querySelectorAll('.custom-popup-banner'));
      var closeBanner = localStorage.getItem('closeBanner');
      if (_$banners.length && !closeBanner) {
        _$banners.forEach(function ($banner) {
          $banner.classList.add('is-active');
        });
      }

      // Close banners
      var $closeButtons = [].slice.call(document.querySelectorAll('.custom-popup-banner .banner-close'));
      if ($closeButtons.length) {
        $closeButtons.forEach(function ($closeButton) {
          $closeButton.addEventListener('click', function (e) {
            e.preventDefault();

            var $banners = [].slice.call(document.querySelectorAll('.custom-popup-banner'));
            $banners.forEach(function ($banner) {
              $banner.remove();
            });

            localStorage.setItem('closeBanner', 'true');
            syncMargin();
          });
        });
      }
    }

    // Open Popup
    var $banners = [].slice.call(document.querySelectorAll('.custom-popup-banner'));
    var $popup = document.querySelector('.custom-popup');
    if ($banners.length && $popup) {
      $banners.forEach(function ($banner) {
        var $triggerPopup = $banner.querySelector('.banner-button');
        $triggerPopup.addEventListener('click', function (e) {
          e.preventDefault();
          $popup.classList.add('is-active');
        });
      });

      // Close Popup
      var $closePopupTrigger = $popup.querySelector('.custom-popup-close');
      $closePopupTrigger.addEventListener('click', function (e) {
        e.preventDefault();
        $popup.classList.remove('is-active');
        localStorage.setItem('closePopupAuto', 'true');
      });

      // Auto-open popup
      var closePopupAuto = localStorage.getItem('closePopupAuto');
      if ($popup.classList.contains('auto-open') && !closePopupAuto) {
        setTimeout(function () {
          $popup.classList.add('is-active');
        }, 5000);
      }
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();