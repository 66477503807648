'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.PostalCodes = function PostalCodes() {
  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    return true;
  };

  var getCoordinates = function getCoordinates(postalCode, _callback) {
    return fetch('/actions/site-module/default/get-coordinates?postalCode=' + postalCode).then(function (response) {
      return response.json();
    }).then(function (data) {
      _callback(data);
    });
  };

  var calculateDistance = function calculateDistance(lat1, lon1, lat2, lon2) {
    var unit = 'K';

    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist *= 1.609344;
    }
    if (unit === 'N') {
      dist *= 0.8684;
    }

    return dist;
  };

  // Expose public methods & properties
  return {
    init: init,
    getCoordinates: getCoordinates,
    calculateDistance: calculateDistance
  };
}();