'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/* global site */
window.site = window.site || {};

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  var config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {}
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = options || {};

    if (options.env) {
      config.env = options.env;
    }
    if (options.csrf) {
      config.csrf = options.csrf;
    }
    if (options.csrfName) {
      config.csrfName = options.csrfName;
    }
    if (options.locale) {
      config.locale = options.locale;
    }
    if (options.device) {
      config.device = options.device;
    }
    if (options.preview) {
      config.preview = options.preview;
    }
    if (options.general) {
      config.general = options.general;
    }

    // Initialize child classes
    if (_typeof(site.ShareButton) === 'object') {
      site.ShareButton.init();
    }
    if (_typeof(site.AjaxPagination) === 'object') {
      site.AjaxPagination.init();
    }
    // if (typeof site.CookiePolicy === 'object') { site.CookiePolicy.init(); }
    if (_typeof(site.ScrollAnchor) === 'object') {
      site.ScrollAnchor.init();
    }

    if (_typeof(site.SearchForm) === 'object') {
      site.SearchForm.init();
    }
    if (_typeof(site.Menu) === 'object') {
      site.Menu.init();
    }

    if (_typeof(site.Hero001) === 'object') {
      site.Hero001.init();
    }
    if (_typeof(site.Grid002) === 'object') {
      site.Grid002.init();
    }
    if (_typeof(site.Arguments002) === 'object') {
      site.Arguments002.init();
    }
    if (_typeof(site.Testimonials001) === 'object') {
      site.Testimonials001.init();
    }
    if (_typeof(site.Blog001) === 'object') {
      site.Blog001.init();
    }
    if (_typeof(site.Blog003) === 'object') {
      site.Blog003.init();
    }
    if (_typeof(site.Career001) === 'object') {
      site.Career001.init();
    }
    if (_typeof(site.Career002) === 'object') {
      site.Career002.init();
    }
    if (_typeof(site.Content001) === 'object') {
      site.Content001.init();
    }
    if (_typeof(site.Faq001) === 'object') {
      site.Faq001.init();
    }
    if (_typeof(site.Team001) === 'object') {
      site.Team001.init();
    }
    if (_typeof(site.Map001) === 'object') {
      site.Map001.init();
    }
    if (_typeof(site.Map002) === 'object') {
      site.Map002.init();
    }
    if (_typeof(site.Appointment001) === 'object') {
      site.Appointment001.init();
    }
    if (_typeof(site.Syndroms001) === 'object') {
      site.Syndroms001.init();
    }
    if (_typeof(site.Filter001) === 'object') {
      site.Filter001.init();
    }
    if (_typeof(site.Newsletter) === 'object') {
      site.Newsletter.init();
    }
    if (_typeof(site.MobileAppointmentButton) === 'object') {
      site.MobileAppointmentButton.init();
    }
    if (_typeof(site.MobileStepsOfCare) === 'object') {
      site.MobileStepsOfCare.init();
    }
    if (_typeof(site.FormValidation) === 'object') {
      site.FormValidation.init();
    }
    if (_typeof(site.CustomPopup) === 'object') {
      site.CustomPopup.init();
    }
    if (_typeof(site.CreationsNavigation) === 'object') {
      site.CreationsNavigation.init();
    }
    if (_typeof(site.PostalCodes) === 'object') {
      site.PostalCodes.init();
    }

    // if (typeof site.FBChat === 'object') { site.FBChat.init(); }

    // if (typeof site.GeolocationServices === 'object') { site.GeolocationServices.init(); }

    var lightbox = GLightbox();

    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      // debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      // throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 0, // offset (in px) from the original trigger point
      delay: 400, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: 'ease-out', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom' // defines which position of the element regarding to window should trigger the animation

    });

    console.table(options);

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  var getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
    config: getConfig
  };
}();