'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Blog001 = function Blog001() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $container = document.querySelector('.blog001');

    if ($container) {
      // Filters
      var $filterForm = $container.querySelector('.blog001-filter-form');
      var $filterSelect = $filterForm.querySelector('.blog001-filter-select');

      $filterSelect.addEventListener('change', function (e) {
        e.preventDefault();
        $filterForm.submit();
      });

      // Infinite Scroll Like
      var $loadMoreButton = $container.querySelector('[data-load-more]');
      if ($loadMoreButton) {
        $loadMoreButton.addEventListener('click', function (e) {
          e.preventDefault();
          $loadMoreButton.classList.add('is-loading');

          var apiURL = $loadMoreButton.getAttribute('href');
          var $ajaxZone = $container.querySelector('.blog001-listing');

          fetch(apiURL, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            method: 'GET'
          }).then(function (resp) {
            return resp.text();
          }).then(function (data) {
            $loadMoreButton.classList.remove('is-loading');

            if (data) {
              // Fetch and transform response to an HTML object
              var htmlResponse = document.createElement('div');
              htmlResponse.innerHTML = data;

              var $refreshArticle = htmlResponse.querySelector('.blog001-listing').innerHTML;
              $ajaxZone.innerHTML += $refreshArticle;

              var $ajaxLoadMoreButton = htmlResponse.querySelector('[data-load-more]');
              if ($ajaxLoadMoreButton) {
                $loadMoreButton.setAttribute('href', $ajaxLoadMoreButton.getAttribute('href'));
              } else {
                $loadMoreButton.remove();
              }
            }
          });
        });
      }
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();