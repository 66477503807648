'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Career002 = function Career002() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $container = document.querySelector('.career002');

    if ($container) {
      // Filters
      var $filtersForm = $container.querySelector('.career002-filters');
      var $filters = [].slice.call($filtersForm.querySelectorAll('.career002-select'));

      $filters.forEach(function ($filter) {
        $filter.addEventListener('change', function (e) {
          e.preventDefault();
          $filtersForm.submit();
        });
      });

      // Auto-scroll
      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);

      if (urlParams.get('domain') || urlParams.get('place') || window.location.href.search('/p') >= 0) {
        var $menu = document.querySelector('.header');
        window.scrollTo(0, $filtersForm.offsetTop - $menu.offsetHeight - 40);
      }
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();