'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.SearchForm = function SearchForm() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $trigger = document.querySelector('.header .search-container > svg');

    if ($trigger) {
      var $searchForm = document.querySelector('.header .search-container');

      $trigger.addEventListener('click', function (e) {
        e.preventDefault();
        var $searchInput = document.querySelector('.header .search-container > input');

        if ($searchForm.classList.contains('is-active') && $searchInput.value.length) {
          $searchForm.submit();
        } else {
          $searchForm.classList.add('is-active');
          $searchInput.focus();
        }
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();