'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Maps.
 * @class Map
 * @static
 */
site.Map002 = function Map002() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $block = document.querySelector('.map002 .clinic-open-hours');

    if ($block) {
      // Get current day open hours
      var now = new Date();
      var index = now.getDay();

      var currentDay = [].slice.call($block.querySelectorAll('.clinic-day'))[index];

      var openHour = currentDay.dataset.open.split('h');
      var dateOpen = new Date();
      dateOpen.setHours(parseInt(openHour[0]));
      dateOpen.setMinutes(parseInt(openHour[1]));
      var closeHour = currentDay.dataset.close.split('h');
      var dateClose = new Date();
      dateClose.setHours(parseInt(closeHour[0]));
      dateClose.setMinutes(parseInt(closeHour[1]));

      if (now >= dateOpen && now < dateClose) {
        var $div = document.querySelector('.map002 .clinic-info');
        $div.querySelector('span').textContent = currentDay.dataset.close;
        $div.classList.add('is-active');
      }

      // Add custom closed days
      var $closedDates = [].slice.call(document.querySelectorAll('.map002 .custom-closed-date'));

      if ($closedDates.length) {
        var closedDates = [];

        $closedDates.forEach(function ($date) {
          closedDates.push($date.dataset.closedDate);
        });

        if (closedDates.length) {
          var $daysOrder1 = [].slice.call(document.querySelectorAll('.map002 .clinic-open-hours .clinic-day.order-1'));
          var $daysOrder2 = [].slice.call(document.querySelectorAll('.map002 .clinic-open-hours .clinic-day.order-2'));
          var $finalDays = [];
          [$daysOrder1, $daysOrder2].forEach(function ($daysBatch) {
            $daysBatch.forEach(function ($dayObject) {
              $finalDays.push($dayObject);
            });
          });
          $finalDays.forEach(function ($day, dayIndex) {
            var dayDate = new Date();
            dayDate.setDate(dayDate.getDate() + dayIndex);

            var dateString = dayDate.getUTCFullYear() + '-' + ('0' + (dayDate.getUTCMonth() + 1)).slice(-2) + '-' + ('0' + dayDate.getUTCDate()).slice(-2);

            if (closedDates.includes(dateString)) {
              var $span = $day.querySelector('span');
              var $text = document.querySelector('.invisible-closed');

              $span.textContent = $text.textContent;
              $span.classList.add('red');
            }
          });
        }
      }
    }

    // Return success
    return true;
  };

  var initMap = function initMap() {

    var $map = document.querySelector('.map002 .map-list-container #map');

    var clinic = JSON.parse($map.dataset.clinic);

    var bounds = new google.maps.LatLngBounds();

    // Init map
    var map = new google.maps.Map($map);

    var latLng = { lat: parseFloat(clinic.lat), lng: parseFloat(clinic.lng) };
    var marker = new google.maps.Marker({
      map: map,
      position: latLng,
      draggable: false,
      icon: { url: 'data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyOCI+PHBhdGggZD0iTTEwIC42NjdBOS4xNCA5LjE0IDAgMDAuODMxIDkuODM0YzAgNS4wODMgOS4xNjcgMTcuNSA5LjE2NyAxNy41czkuMTY3LTEyLjQxNyA5LjE2Ny0xNy41QTkuMTQgOS4xNCAwIDAwMTAgLjY2N3pNMTAgMTRhNC4xMjcgNC4xMjcgMCAwMS00LjE2OC00LjE2NyA0LjEyNyA0LjEyNyAwIDAxNC4xNjctNC4xNjcgNC4xMjcgNC4xMjcgMCAwMTQuMTY3IDQuMTY3QTQuMTI3IDQuMTI3IDAgMDExMCAxNHoiIGZpbGw9IiMwMEJDRTciLz48L3N2Zz4=', scaledSize: new google.maps.Size(40, 40) },
      optimized: false
    });
    var loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

    map.setCenter(loc);
    map.setZoom(15);
  };

  // Expose public methods & properties
  return {
    init: init,
    initMap: initMap
  };
}();