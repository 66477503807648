'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Filter001 = function Filter001() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $selectFilters = document.querySelectorAll('[data-identify-filter]');

    if ($selectFilters) {
      $selectFilters.addEventListener('change', function (e) {
        var filter = e.target.value;

        if (filter === 'syndromes') {
          window.location.href = window.location.origin + window.location.pathname + "?page=a-z#syndromes";
        } else {
          window.location.href = window.location.origin + window.location.pathname + "#zones";
        }
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();